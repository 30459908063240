import 'focus-visible'
import Head from 'next/head'
import React, { useEffect } from 'react';
import "../styles/main.css"
import { Layout } from '@monorepo-nx/lander/ui'
import { useRouter } from "next/router";
import { GoogleTagManager } from '@next/third-parties/google'
import { SpeedInsights } from '@vercel/speed-insights/react';

// !! importing analytics specifically to reduce bundle size
import { SendPageView, saveMarketingCookies } from '@monorepo-nx/std-watch/services/analytics';
import Script from 'next/script';
import { AppProvider } from '@monorepo-nx/std-watch/services';

function CustomApp({ Component, pageProps }) {

  const router = useRouter();
  useEffect(() => {
    saveMarketingCookies();
    const handleRouteChange = (url) => {
      SendPageView(url);
    };
    router.events.on("routeChangeComplete", handleRouteChange);
    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router.events]);

  const disclaimer = {
    title: "The information provided herein does not constitute an expert advice, nor intended to replace such advice.",
    url: '/terms'
  };
  const complianceLinks = [
    { title: 'CCPA Notice', url: '/ccpa' },
    { title: 'Advertising Disclosure', url: '/advertising' },
    { title: 'Privacy Policy', url: '/privacy' },
    { title: 'Terms of Use', url: '/terms' },
    { title: 'How We Compare', url: '/how-we-compare-and-rate' },
  ];


  return (
    <AppProvider subMenu={pageProps?.subMenu} footer={pageProps?.footer} categoryPage={pageProps?.categoryPage} endpoint={pageProps.endpoint} siteConfig={pageProps.siteConfig}>
      <Layout domain={pageProps.domain} complianceLinks={complianceLinks} disclaimer={disclaimer}>
        {/* <div mainMenu={[]}> */}
        <Head>
          {/* Every page is expected to contains a title property */}
          <title>{Component.title ? Component.title : pageProps.title}| {pageProps.slug}</title>
          <meta
            name="description"
            content={pageProps.description || "A landing page for your next project"}
          />
          <link rel="icon" type="image/png" href={pageProps?.siteConfig?.favicon?.url} />
          <meta name="referrer" content="origin" />
        </Head>
        {pageProps?.siteConfig?.gaMeasurementId ?
          <>
            {/* Global Site Tag (gtag.js) - Google Analytics */}
            <Script
              strategy="afterInteractive"
              src={`https://www.googletagmanager.com/gtag/js?id=${pageProps?.siteConfig?.gaMeasurementId}`}
            />
            <Script
              id="gtag2"
              strategy="afterInteractive"
              dangerouslySetInnerHTML={{
                __html: `
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', '${pageProps?.siteConfig?.gaMeasurementId}', {
            page_path: window.location.pathname,
          });
        `,
              }}
            /></> : ""}

        {/* Google Tag Manager */}
        <GoogleTagManager gtmId={pageProps?.siteConfig?.gtmId} />

        <Component {...pageProps} />
        <SpeedInsights debug={false} />

      </Layout>
    </AppProvider>
  );
}
export default CustomApp;
